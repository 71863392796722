import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "shader", "toggleButton"];
  static values = {
    collapsed: { type: Boolean, default: true },
    maxVisibleItems: Number,
  };

  connect() {}

  toggle() {
    this.collapsedValue = !this.collapsedValue;
  }

  collapsedValueChanged() {
    this.itemTargets.forEach((item, index) => {
      if (this.maxVisibleItemsValue === 0 || !this.collapsedValue) {
        item.classList.remove("hidden");
      } else if (index >= this.maxVisibleItemsValue) {
        item.classList.add("hidden");
      } else {
        item.classList.remove("hidden");
      }
    });

    if (this.collapsedValue) {
      this.shaderTarget.style.display = "block";
      this.toggleButtonTarget.textContent = "See all +";
    } else {
      this.shaderTarget.style.display = "none";
      this.toggleButtonTarget.textContent = "See less –";
    }
  }
}
